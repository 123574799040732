<template>
	<div class="main">
		<!-- <div class="location">
			<div class="cur-location">
				<span style="line-height: 1">您所在的位置：</span>
			</div>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>系统设置</el-breadcrumb-item>
				<el-breadcrumb-item>小程序管理</el-breadcrumb-item>
			</el-breadcrumb>
		</div> -->
		<common-header :formModel="formInline" @query="query" class="common_header">
			>
			<template v-slot:form>
				<el-form-item :label="$t('Applet.code')" prop="param.code">
					<el-input v-model="formInline.param.code"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Applet.name')" prop="param.name">
					<el-input v-model="formInline.param.name"></el-input>
				</el-form-item>
			</template>
			<template v-slot:tools>
				<tools-btn left-icon="add" @click.native="add()">{{ $t('common.add') }}</tools-btn>
			</template>
		</common-header>
		<div class="table tableHeight">
			<el-table border stripe size="mini" height="100%" :data="tableData">
				<el-table-column show-overflow-tooltip prop="code" :label="$t('Applet.code')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="name" :label="$t('Applet.name')"></el-table-column>
				<el-table-column
					show-overflow-tooltip
					prop="wxMiniAppid"
					:label="$t('Applet.wxMiniAppid')"
				></el-table-column>
				<el-table-column show-overflow-tooltip prop="openCode" :label="$t('Applet.openCode')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="openName" :label="$t('Applet.openName')"></el-table-column>
				<el-table-column
					show-overflow-tooltip
					prop="wxOpenAppid"
					:label="$t('Applet.wxOpenAppid')"
				></el-table-column>
				<el-table-column show-overflow-tooltip prop="mchId" :label="$t('Applet.mchId')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="mchNm" :label="$t('Applet.mchNm')"></el-table-column>
				<el-table-column
					show-overflow-tooltip
					prop="rootAccount"
					:label="$t('Applet.rootAccount')"
				></el-table-column>
				<el-table-column show-overflow-tooltip prop="isUp" :label="$t('common.isUp')">
					<template v-slot="{ row }">
						{{ row.isUp ? $t('common.up') : $t('common.down') }}
					</template>
				</el-table-column>
				<el-table-column :label="$t('common.operation')" width="140">
					<template v-slot="{ row }">
						<el-button size="mini" type="text" @click="goAppletDetails(row)">
							{{ $t('common.details') }}
						</el-button>
						<!-- <el-button size="mini" type="text" @click="isUp(row)">
							{{ row.isUp ? $t('common.down') : $t('common.up') }}
						</el-button> -->
						<el-dropdown trigger="click" style="margin-left: 10px" @command="handleCommand">
							<el-button size="mini" type="text">
								{{ $t('common.moreOperate') }}
								<i class="el-icon-arrow-down el-icon--right"></i>
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="{ row, code: 'isUp' }">
									{{ row.isUp ? $t('common.down') : $t('common.up') }}
								</el-dropdown-item>
								<el-dropdown-item :command="{ row, code: 'resetPassword' }">
									{{ $t('Applet.resetPassword') }}
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<gl-page
			@size-change="sizeChange"
			@current-change="query"
			:current-page="formInline.pageNum"
			:page-size="formInline.pageSize"
			:total="total"
		></gl-page>
		<el-dialog :title="title" :visible.sync="addVisible" width="600" center :close-on-click-modal="false">
			<el-form ref="addForm" :model="addForm" label-width="100px" class="form" :rules="rules">
				<el-form-item :label="$t('Applet.code')" prop="code">
					<el-input size="mini" v-model="addForm.code"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Applet.name')" prop="name">
					<el-input size="mini" v-model="addForm.name"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Applet.codeKey')" prop="codeKey">
					<el-input size="mini" v-model="addForm.codeKey"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Applet.openCode')" prop="openCode">
					<el-input size="mini" v-model="addForm.openCode"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Applet.openName')" prop="openName">
					<el-input size="mini" v-model="addForm.openName"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Applet.openCodeKey')" prop="openCodeKey">
					<el-input size="mini" v-model="addForm.openCodeKey"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Applet.managerAccount')" prop="managerAccount">
					<el-input size="mini" v-model="addForm.managerAccount"></el-input>
				</el-form-item>
				<el-form-item :label="$t('user.initPwd')" prop="initPwd">
					<el-input v-model="addForm.initPwd" type="password" show-password></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addVisible = false">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog
			:title="$t('Applet.resetPassword')"
			:visible.sync="passwordVisible"
			width="450px"
			center
			:close-on-click-modal="false"
		>
			<el-form ref="passwordForm" :model="passwordForm" label-width="100px" :rules="rules">
				<el-form-item :label="$t('Applet.resetPassword')" prop="password">
					<el-input size="mini" type="password" show-password v-model="passwordForm.password"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="passwordVisible = false">取 消</el-button>
				<el-button type="primary" @click="savePassword">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			total: 0,
			tableData: [],
			formInline: {
				pageNum: 1,
				pageSize: 10,
				param: {
					code: '',
					name: ''
				}
			},
			addForm: {
				code: '',
				codeKey: '',
				managerAccount: '',
				name: '',
				openCode: '',
				openCodeKey: '',
				initPwd: '',
				openName: ''
			},
			addVisible: false,
			rules: {
				code: [{ required: true, message: '请输入小程序code', trigger: 'blur' }],
				name: [{ required: true, message: '请输入小程序名称', trigger: 'blur' }],
				codeKey: [{ required: true, message: '请输入小程序key', trigger: 'blur' }],
				openCode: [{ required: true, message: '请输入公众号code', trigger: 'blur' }],
				openName: [{ required: true, message: '请输入公众号名称', trigger: 'blur' }],
				openCodeKey: [{ required: true, message: '请输入公众号key', trigger: 'blur' }],
				managerAccount: [{ required: true, message: '请输入管理员账号', trigger: 'blur' }],
				password: [{ required: true, message: '请输入重置密码', trigger: 'blur' }]
			},
			title: '',
			passwordVisible: false,
			passwordForm: {
				rootAccount: '',
				password: ''
			}
		}
	},
	mounted() {
		this.query()
	},
	methods: {
		savePassword() {
			this.$refs.passwordForm.validate(valid => {
				if (valid) {
					this.$confirm(this.$t('common.confirm') + this.$t('user.resetPwd') + '?', this.$t('common.Tips'), {
						confirmButtonText: this.$t('common.confirm'),
						cancelButtonText: this.$t('common.cancel'),
						closeOnClickModal: false,
						type: 'warning'
					}).then(() => {
						this.ajax.post('/admin/miniApp/manage/resetPassword', this.passwordForm).then(() => {
							this.$message.success(this.$t('common.success'))
							this.passwordVisible = false
							this.query()
						})
					})
				}
			})
		},
		handleCommand(command) {
			switch (command.code) {
				case 'isUp':
					this.isUp(command.row)
					break
				case 'resetPassword':
					this.passwordForm.rootAccount = command.row.organizationId
					this.passwordForm.password = ''
					this.passwordVisible = true
					break
			}
		},
		goAppletDetails(row) {
			this.$router.push(
				`/AppletDetails?code=${row.code || ''}&name=${row.name || ''}&openCode=${row.openCode ||
					''}&openName=${row.openName || ''}&id=${row.organizationId || ''}`
			)
		},
		isUp(row) {
			console.log(row)
			this.ajax.post('/admin/miniApp/manage/isUp', { id: row.organizationId, operator: !row.isUp }).then(() => {
				this.$message.success(this.$t('common.success'))
				this.query()
			})
		},
		save() {
			this.$refs.addForm.validate(valid => {
				if (valid) {
					this.ajax.post('/admin/miniApp/manage/save', this.addForm).then(() => {
						this.addVisible = false
						this.query()
					})
				}
			})
		},
		add() {
			this.addVisible = true
			this.title = this.$t('common.add')
			this.$nextTick(() => {
				this.$refs.addForm.resetFields()
			})
		},
		query(i) {
			if (isNaN(i)) {
				i = 1
			}
			this.formInline.pageNum = i
			this.ajax.post('/admin/miniApp/manage/pageInfo', this.formInline).then(res => {
				console.log(res)
				this.tableData = res.list
				this.total = res.total
			})
		},
		sizeChange(i) {
			this.formInline.pageSize = i
			this.query()
		}
	}
}
</script>
<style lang="scss" scoped></style>
